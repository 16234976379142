
import { Vue, Component, Watch } from 'vue-property-decorator'
import { NavigationTabs, MSCSearchTree, SearchTabs, Classification, FreeToolsMSCResult } from '@/components/freetools'
import { FreeToolsAPI } from '@/api/freetools-api'
import {
  NavigationTabs as SubscriberNavigationTabs,
  SearchTabs as SubscriberSearchTabs,
} from '@/components/navigation-tabs'
import { updateMathJax } from '@/utils/utils'

@Component({
  components: {
    NavigationTabs,
    SubscriberNavigationTabs,
    MSCSearchTree,
  },
})
export default class MSCSearch extends Vue {
  //
  //  REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.searchMSC
  subscriberSelectedTab = SubscriberSearchTabs.mscSearch
  isSubscriberPage = false
  classifications: Classification[] = []
  showInvalidItems = false
  loading = true
  searchText = ''
  showInvalidClassesButton = false
  pdfLink = require('@/assets/pdf/classifications.pdf')

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRoutechanged() {
    document.title = 'MSC Search - MathSciNet'
    const text = this.$route.query.text as string

    if (text !== undefined && text.trim() !== '') {
      this.searchText = text
      this.fetchClassificationsBySearchText()
      document.title = `MSC ${text.trim()} Search - MathSciNet`
    } else {
      this.searchText = ''
      this.fetchFirstLevelMSC()
    }

    // console.log(this.$route.name)
    this.isSubscriberPage = this.$route.name === 'SearchMsc'
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    document.title = 'MSC Search - MathSciNet'
    const text = this.$route.query.text as string
    if (text !== undefined && text.trim() !== '') {
      document.title = `MSC ${text.trim()} Search - MathSciNet`
    }
    window.onClickMSCHandler = this.onClickMSCHandler.bind(this)
    updateMathJax()
  }

  updated() {
    updateMathJax()
  }

  //
  // COMPUTED PROPERTIES
  //

  get routerComponentName() {
    return this.isSubscriberPage ? 'SearchMsc' : 'FreeToolsSearchMsc'
  }

  //
  // METHODS
  //
  async fetchFirstLevelMSC() {
    this.loading = true

    const result = await FreeToolsAPI.getFirstLevelMSC()

    this.checkInvalidsToShowButton(result)

    this.classifications = result.map(this.mapClassifications)

    this.checkIfThereAreInvalidClassesOnly(this.classifications)

    this.loading = false
  }

  async fetchGroupSubLevelMSC(group: string) {
    const result = await FreeToolsAPI.getGroupSublevelMSC(group)

    return result
  }

  async fetchClassificationsBySearchText() {
    if (this.searchText.trim().length === 0) return

    this.loading = true

    const result = await FreeToolsAPI.searchClassifications(this.searchText.trim())

    this.checkInvalidsToShowButton(result)

    this.classifications = []
    result.filter(c => c.level === 1)
      .forEach(msc => {
        const mappedMSC = this.mapClassifications(msc)

        mappedMSC.opened = true
        mappedMSC.children = this.processSubLevels(result, mappedMSC.code)
          .map(msc => ({ ...msc, opened: true }))

        this.classifications.push(mappedMSC)
      })

    this.checkIfThereAreInvalidClassesOnly(this.classifications)

    this.loading = false
  }

  checkIfThereAreInvalidClassesOnly(classifications: Classification[]) {
    const invalidClasses = classifications.filter(c => c.invalid)

    if (invalidClasses.length === classifications.length) {
      this.showInvalidItems = true
    } else {
      this.showInvalidItems = false
    }
  }

  checkInvalidsToShowButton(result: FreeToolsMSCResult[]) {
    const invalids = result.filter(msc => msc.endYear.trim().toLowerCase() !== 'current')
    this.showInvalidClassesButton = invalids.length > 0
  }

  searchClassifications() {
    if (this.searchText.trim().length === 0 || this.$route.query.text === this.searchText.trim()) return

    this.$router.push({
      name: this.routerComponentName,
      query: {
        text: this.searchText.trim(),
      },
    })
  }

  clearInputText() {
    if (this.$route.query.text === '') return

    this.$router.replace({
      name: this.routerComponentName,
    })
  }

  mapClassifications(msc: FreeToolsMSCResult) {
    const searchText = this.searchText.trim().replace(/\*$/g, '')
    const code = msc.code.replace(/-XX$|xx$/g, '')
    // const code = msc.code
    // const highlightPatter = new RegExp(`(${searchText})`, 'gi')

    const codeHTML = searchText !== ''
      // ? code.replace(highlightPatter, '<mark>$1</mark>')
      ? code
      : undefined
    const description = searchText !== ''
      ? msc.description
        .replace(
          /([0-9][0-9][A-Z-.][0-9Xx][0-9Xx])/g,
          '<a href="#" onclick="onClickMSCHandler(event, \'$1\'); return false;">$1</a>'
        )
        // .replace(highlightPatter, '<mark>$1</mark>')
      : msc.description
        .replace(
          /([0-9][0-9][A-Z-.][0-9Xx][0-9Xx])/g,
          '<a href="#" onclick="onClickMSCHandler(event, \'$1\'); return false;">$1</a>'
        )

    return {
      code,
      codeHTML,
      range: `${msc.startYear}&ndash;${msc.endYear.replace('Current', 'now')}`,
      description,
      invalid: msc.endYear.trim() !== 'Current',
      opened: false,
      children: [],
      group: msc.group,
      extraIndent: false,
      loadingChildren: false,
    } as Classification
  }

  showInvalidClasses() {
    this.showInvalidItems = !this.showInvalidItems
  }

  processSubLevels(msc: FreeToolsMSCResult[], group: string) {
    const level3 = msc
      .filter(msc => msc.level === 3)
      .map(this.mapClassifications)

    const specialLevel2 = msc
      .filter(msc => msc.group === group && msc.level === 3)
      .map(this.mapClassifications)
      .map(msc => ({ ...msc, extraIndent: true }))

    const level2 = msc
      .filter(msc => msc.group === group && msc.level === 2)
      // .map(msc => ({ ...msc, code: msc.code.replace(/(.*)xx$/g, '$1') }))
      .map(this.mapClassifications)
      .map(msc => ({ ...msc, children: level3.filter(v => v.group === msc.code) }))

    return [...specialLevel2, ...level2]
  }

  //
  // EVENT HANDLERS
  //
  async open(msc: Classification, depth: number) {
    if (msc.children.length === 0 && depth < 2) {
      msc.loadingChildren = true
      const result = await this.fetchGroupSubLevelMSC(msc.code)
      msc.children = this.processSubLevels(result, msc.code)
      msc.loadingChildren = false
    }
    msc.opened = !msc.opened
  }

  onClickMSCHandler(e: Event, code: string) {
    e.stopPropagation()

    this.$router.push({
      name: this.routerComponentName,
      query: {
        text: code
          .replace(/(.*)xx$/g, '$1')
          .replace(/<mark>(.*)<\/mark>/gi, '$1'),
      },
    })
  }

  onClickDownloadPDF() {
    window.open(this.pdfLink, '_blank')
  }
}
